import { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import CompositeSelection from 'bloko/blocks/compositeSelection';
import Link, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';
import urlParser from 'bloko/common/urlParser';

import translation from 'src/components/translation';
import useFetchAreaTree from 'src/hooks/useFetchAreaTree';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    link: 'employers.company.another.cities',
    submit: 'treeselector.save',
    cancel: 'treeselector.cancel',
    notFound: 'treeselector.notFound',
    placeholder: 'treeselector.quicksearch',
};

const AreaSelection: TranslatedComponent = ({ trls }) => {
    const dispatch = useDispatch();
    useFetchAreaTree();

    const areaTree = useSelector((state) => state.areaTree);
    const collection = useMemo(() => fromTree(areaTree), [areaTree]);

    const dummy: string[] = [];

    const handleSelect = useCallback(
        (items: string[]) => {
            const url = urlParser(window.location.href);
            url.params.area = items[0];
            dispatch(push(url.href));
        },
        [dispatch]
    );

    return (
        <CompositeSelection
            value={dummy}
            collection={collection}
            onChange={handleSelect}
            singleChoice
            trl={{
                submit: trls[TrlKeys.submit],
                cancel: trls[TrlKeys.cancel],
                searchPlaceholder: trls[TrlKeys.placeholder],
                notFound: trls[TrlKeys.notFound],
            }}
        >
            {({ showTreeSelectorPopup, renderTagList }) => (
                <div>
                    {renderTagList()}
                    <Link appearance={LinkAppearance.Pseudo} kind={LinkKind.Secondary} onClick={showTreeSelectorPopup}>
                        {trls[TrlKeys.link]}
                    </Link>
                </div>
            )}
        </CompositeSelection>
    );
};

export default translation(AreaSelection);
