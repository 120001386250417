import { useMemo } from 'react';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatNewLine } from 'bloko/common/trl';
import urlParser from 'bloko/common/urlParser';

import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    found: 'admin.employers.list.numFound',
    one: 'main.counter.employers.one',
    some: 'main.counter.employers.some',
    many: 'main.counter.employers.many',
    opened: 'employers.company.open.vacancies',
    all: 'employers.company.all.vacancies',
    notFoundText: 'employers.company.no.items',
    notFoundLink: 'employers.company.use.search',
};

const Header: TranslatedComponent = ({ trls }) => {
    const { area, employers } = useSelectorNonNullable((state) => state.employersCompany);
    const { pathname, search, query } = useSelectorNonNullable(({ router }) => router.location);

    const href = useMemo(() => {
        const url = urlParser(`${pathname}${search}`);
        url.params.vacanciesRequired = url.params.vacanciesRequired ? null : true;
        return url.href;
    }, [pathname, search]);

    if (!employers) {
        return null;
    }

    if (!employers.count) {
        return (
            <>
                <VSpacing base={5} />
                <Text>
                    {formatNewLine(trls[TrlKeys.notFoundText])}
                    {NON_BREAKING_SPACE}
                    <Link to={`/employers_list?areaId=${area.id}`}>{trls[TrlKeys.notFoundLink]}</Link>
                </Text>
            </>
        );
    }

    return (
        <>
            <VSpacing base={5} />
            <Text>
                <Conversion
                    value={employers.count}
                    zero={trls[TrlKeys.many]}
                    one={trls[TrlKeys.one]}
                    some={trls[TrlKeys.some]}
                    many={trls[TrlKeys.many]}
                />
                {NON_BREAKING_SPACE}
                {`${trls[TrlKeys.found]},`}
                {NON_BREAKING_SPACE}
                <Link to={href}>{query.vacanciesRequired ? trls[TrlKeys.all] : trls[TrlKeys.opened]}</Link>
            </Text>
        </>
    );
};

export default translation(Header);
