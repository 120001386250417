import { FC, ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import classnames from 'classnames';

import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumb';
import BreadcrumbsDivider from 'src/components/Breadcrumbs/BreadcrumbsDivider';

const Breadcrumbs: FC<ComponentPropsWithoutRef<'div'> & PropsWithChildren> = ({ children, className, ...props }) => (
    <div {...props} className={classnames('breadcrumbs', className)}>
        {children}
    </div>
);

export { Breadcrumb, BreadcrumbsDivider };

export default Breadcrumbs;
